import AuthNavigator from "../AuthNavigator/AuthNavigator";
import React from "react";
import { ThemeProvider } from "@mui/material";
import { CustomTheme } from "./Theme";
import "./App.css";

const loadCookiebotScript = () => {
  const script = document.createElement("script");
  script.id = "Cookiebot";
  script.src = "https://consent.cookiebot.com/uc.js";
  script.setAttribute("data-cbid", process.env.REACT_APP_COOKIEBOT_CBID);
  script.setAttribute("data-blockingmode", process.env.REACT_APP_COOKIEBOT_BLOCKINGMODE);
  script.type = "text/javascript";
  script.async = true;
  document.head.appendChild(script);
};

class App extends React.Component {

  componentDidMount() {

	document.title = "B120 - The Life Tresor";

    const handleCookieConsent = () => {
      if (window.Cookiebot) {
        const consentGiven = window.Cookiebot.consents.given;
        if (consentGiven) {
          //console.log('User has given consent:', window.Cookiebot.consents);
        } else {
          //console.log('User has not given consent');
        }
      }
    };

    document.addEventListener('CookieConsentDeclaration', handleCookieConsent);

    loadCookiebotScript();

    // We are using Google Tag Manager (GTM) instead of Global Site Tag (gtag.js),
    // so this part is not needed at the moment. GTM is initialized in index.js.
    // const gaScript = document.createElement("script");
    // gaScript.async = true;
    // gaScript.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_TRACKING_ID}`;
    // document.head.appendChild(gaScript);
    //
    // //Google Analytics.
    // gaScript.onload = () => {
    //   window.dataLayer = window.dataLayer || [];
    //   function gtag() { window.dataLayer.push(arguments); }
    //   gtag('js', new Date());
    //   gtag('config', process.env.REACT_APP_GA_TRACKING_ID);
    // };

    //Hotjar
    const hotjarLoader = document.createElement("script");
    hotjarLoader.src = "https://b120.eu/hotjar.js";
    hotjarLoader.async = true;
    document.head.appendChild(hotjarLoader);

    //google login
    const gscript = document.createElement("script");
    gscript.src = "https://accounts.google.com/gsi/client";
    gscript.async = true;
    document.body.appendChild(gscript);
    gscript.onload = (evt) => {
      window.google.accounts.id.initialize({
        client_id: process.env.REACT_APP_GOOGLE_CLIENT_ID,
        callback: (response) => {
          localStorage.setItem("jwt", response.credential);

		  let params = new URL(document.location.toString()).searchParams;

          let xhr = new XMLHttpRequest();

		  let json;

		  if (params.has("invitationToken")) {
          json = JSON.stringify({
            jwt: response.credential,	
			invitationToken: params.get("invitationToken")			
          });			  
		  } else {
          json = JSON.stringify({
            jwt: response.credential,		
          });
		  }

          let jsonResponse;

          xhr.onloadend = function () {
            if (xhr.status === 200) {
              //console.log("google success - local");
              //console.log(xhr.response);
              jsonResponse = JSON.parse(xhr.response);
              localStorage.setItem("token", jsonResponse.authorisation.token);
              document.location.href = "/";
            }
            if (xhr.status === 201) {
              //console.log("google success - remote");
              //console.log(xhr.response);
              jsonResponse = JSON.parse(xhr.response);
              localStorage.setItem("token", jsonResponse.token);
              document.location.href = "/";
            } else {
              //console.log("google error " + this.status);
            }
          };



          xhr.open("POST", process.env.REACT_APP_BASE_URL + "/tokens/google");
          xhr.setRequestHeader(
            "Content-type",
            "application/json; charset=utf-8"
          );
          xhr.send(json);
        },
      });
    };

    //apple login
    const ascript = document.createElement("script");
    ascript.src =
      "https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/en_US/appleid.auth.js";
    ascript.async = true;
    document.body.appendChild(ascript);
    ascript.onload = (evt) => {
      window.AppleID.auth.init({
        clientId: process.env.REACT_APP_APPLE_CLIENT_ID,
        scope: "name email",
        redirectURI: window.location.origin,
        state: "SignInUserAuthenticationRequest",
        nonce: String(Date.now()),
        usePopup: true,
      });
    };


  // Listen for authorization success.
  document.addEventListener('AppleIDSignInOnSuccess', (event) => {
    // Handle successful response.
		  console.log(event.detail.authorization);

		  let params = new URL(document.location.toString()).searchParams;

          let xhr = new XMLHttpRequest();

		  let json = event.detail;
		  if (params.has("invitationToken")) json.invitationToken = params.get("invitationToken");
		  if (params.has("referralToken")) json.referralToken = params.get("referralToken");
/*
		  if (params.has("invitationToken")) {
          json = JSON.stringify({
            authorization: event.detail.authorization,
			user: event.detail.user,
			invitationToken: params.get("invitationToken")			
          });			  
		  } else {
          json = JSON.stringify({
            authorization: event.detail.authorization,
			user: event.detail.user
          });
		  }
*/

          let jsonResponse;

          xhr.onloadend = function () {
            if (xhr.status === 201) {
              //console.log("google success - remote");
              //console.log(xhr.response);
              jsonResponse = JSON.parse(xhr.response);
              localStorage.setItem("token", jsonResponse.token);
              document.location.href = "/";
            } else {
              //console.log("apple error " + this.status);
            }
          };

          xhr.open("POST", process.env.REACT_APP_BASE_URL + "/tokens/apple");
          xhr.setRequestHeader(
            "Content-type",
            "application/json; charset=utf-8"
          );
          xhr.send(JSON.stringify(json));
		  
  });


  // Listen for authorization failures.
  document.addEventListener('AppleIDSignInOnFailure', (event) => {
     // Handle error.
	console.log("apple error ");	     
	console.log(event.detail);
    
  });

  }
  
  componentWillUnmount() {
    document.removeEventListener('CookieConsentDeclaration', this.handleCookieConsent);
  
  
  }

  render() {
    return (
      <>
        <ThemeProvider theme={CustomTheme}>
          <AuthNavigator />
        </ThemeProvider>
      </>
    );
  }
}

export default App;
